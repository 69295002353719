<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:body>
                <b-row>
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                                >
                                <template v-slot:label>
                                {{ $t('globalTrans.fiscal_year')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="search.fiscal_year_id"
                                :options="fiscalList"
                                id="fiscal_year_id"
                                >
                                    <template v-slot:first>
                                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col>
                        <b-button type="button" class="float-left" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('cropInfo.cultivation_production_target') }}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <b-button class="btn_add_new" size="sm" v-b-modal.modal-4 @click="resetItem">
                            <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" bordered striped hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(carryovers_name)="data">
                                            <span class="capitalize">{{ data.item.carryovers_name }}</span>
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                                                <i class="ri-ball-pen-fill"></i>
                                            </a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId" :item="item"/>
        </b-modal>

    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { cultivationList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form
    },
    data () {
        return {
            search: {
                fiscal_year_id: 0,
                production_season_id: 0
            },
            item: '',
            editItemId: 0
        }
    },
    computed: {
        fiscalList: function () {
            return this.$store.state.commonObj.fiscalYearList
        },
        formTitle () {
            return (this.editItemId === 0) ? this.$t('cropInfo.cultivation_production_target') + ' ' + this.$t('globalTrans.entry') : this.$t('cropInfo.cultivation_production_target') + ' ' + this.$t('globalTrans.update')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('globalTrans.fiscal_year'), class: 'text-center' },
                { label: this.$t('seedsConfig.productionSeason'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'fiscal_year_bn' },
                { key: 'season_bn' },
                { key: 'action' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'fiscal_year' },
                { key: 'season_en' },
                { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        this.loadData()
    },
    mounted () {
        core.index()
    },
    methods: {
        resetItem () {
            this.editItemId = 0
            this.item = ''
        },
        edit (item) {
            this.editItemId = item.id
            this.item = item
        },
        dataChange () {
            this.loadData()
        },
        default () {
            return {
                id: this.rows.length,
                carryovers_name: '',
                carryovers_name_bn: '',
                editable: false
            }
        },
        async searchData () {
            this.loadData()
        },
        loadData () {
            var carryoverIdSerach = ''
            if (this.search.carryover_id) {
                carryoverIdSerach = this.search.carryover_id.value
            }
            const params = Object.assign({}, this.search, { master_carryover_id: carryoverIdSerach, page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(seedFertilizerServiceBaseUrl, cultivationList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', this.relationalData(response.data.data))
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        relationalData (data) {
            let tmpFiscalYear = {}
            let tmpProductionSeason = {}
            const listData = data.map((item, index) => {
                tmpFiscalYear = this.$store.state.commonObj.fiscalYearList.filter(fiscalYear => fiscalYear.status === 0 && fiscalYear.value === item.fiscal_year_id)
                tmpProductionSeason = this.$store.state.SeedsFertilizer.commonObj.productionSeasonList.filter(productionSeason => productionSeason.status === 1 && productionSeason.value === item.production_season_id)
                const orgData = {
                    fiscal_year: tmpFiscalYear[0] !== undefined ? tmpFiscalYear[0].text_en : '',
                    fiscal_year_bn: tmpFiscalYear[0] !== undefined ? tmpFiscalYear[0].text_bn : '',
                    season_en: tmpProductionSeason[0] !== undefined ? tmpProductionSeason[0].text_en : '',
                    season_bn: tmpProductionSeason[0] !== undefined ? tmpProductionSeason[0].text_bn : ''
                }
                return Object.assign({}, item, orgData)
            })
            return listData
        }
    }
}
</script>
