<template>
    <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template v-slot:body>
              <b-row>
                <b-col lg="12" sm="12" class="form-for-textarea">
                  <b-overlay :show="loading">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset"  enctype="multipart/form-data">
                      <b-row>
                         <b-col lg="6" sm="12">
                            <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="fiscal_year_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('globalTrans.fiscal_year')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                      plain
                                      v-model="form.fiscal_year_id"
                                      :options="fiscalList"
                                      id="fiscal_year_id"
                                      :disabled="disableButton"
                                      >
                                        <template v-slot:first>
                                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                      </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                          <b-col lg="6" sm="12">
                          <ValidationProvider name="Production Season" vid="production_season_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="Season"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{ $t('seedsConfig.productionSeason') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="form.production_season_id"
                                :options="productionSeasonList"
                                id="production_season_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="disableButton"
                                >
                                <template v-slot:first>
                                  <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <iq-card>
                          <template v-slot:headerTitle>
                            <h4 class="card-title" style="width: 100%"> {{$t('cropInfo.crop_info')}}</h4>
                          </template>
                        </iq-card>
                        <b-table-simple bordered>
                            <b-tr>
                                <b-th class="text-center"> {{ $t('globalTrans.sl_no') }} </b-th>
                                <b-th class="text-center"> {{ $t('germplasmReport.crop_type') }}</b-th>
                                <b-th class="text-center"> {{ $t('cropInfo.crop_sub_type') }}</b-th>
                                <b-th class="text-center"> {{ $t('cropInfo.cropName') }}</b-th>
                                <b-th class="text-center"> {{ $t('seedsConfig.category') }}</b-th>
                                <b-th class="text-center"> {{ $t('cropInfo.land_target') }}</b-th>
                                <b-th class="text-center"> {{ $t('cropInfo.production_target') }}</b-th>
                                <b-th class="text-center"> {{ $t('cropInfo.production_ratio') }}</b-th>
                            </b-tr>
                            <template v-if="form.details.length">
                              <template v-for="(cropType, cropTypeIndex) in form.details">
                                <template v-for="(subType, subTypeIndex) in cropType.sub_type_list">
                                  <template v-for="(crop, cropIndex) in subType.crop_list">
                                    <template v-for="(category, categoryIndex) in crop.category_list">
                                      <b-tr :key="'ct-'+ cropTypeIndex+'-st-'+subTypeIndex+'-cr-'+cropIndex+'cat-'+categoryIndex">
                                          <slot v-if="subTypeIndex === 0 && cropIndex === 0 && categoryIndex === 0">
                                            <b-td class="text-center align-middle" :rowspan="cropRowspan(cropType.sub_type_list)">{{ $n(category.count) }}</b-td>
                                            <td class="text-center align-middle" :rowspan="cropRowspan(cropType.sub_type_list)">
                                                {{ currentLocale === 'en' ? cropType.crop_type_name_en : cropType.crop_type_name_bn }}
                                            </td>
                                        </slot>
                                          <slot v-if="cropIndex === 0 && categoryIndex === 0">
                                            <td class="text-center align-middle" :rowspan="categoryRowspan(subType.crop_list)">
                                                {{ currentLocale === 'en' ? subType.sub_type_name_en : subType.sub_type_name_bn }}
                                            </td>
                                        </slot>
                                          <slot v-if="categoryIndex === 0">
                                                <td class="text-center align-middle" :rowspan="crop.category_list.length">
                                                    {{ currentLocale === 'en' ? crop.crop_name_en : crop.crop_name_bn }}
                                                </td>
                                         </slot>
                                          <b-td class="text-center">{{ currentLocale === 'en' ? category.category_name_en : category.category_name_bn }}</b-td>
                                          <b-td>
                                            <ValidationProvider name="Land Target" :vid="'details.'+cropTypeIndex+'.sub_type_list.'+subTypeIndex+'.crop_list.'+cropIndex+'.category_list.'+categoryIndex+'.land_target'" rules="">
                                                <b-form-group
                                                    :label-for="'details.'+cropTypeIndex+'.sub_type_list.'+subTypeIndex+'.crop_list.'+cropIndex+'.category_list.'+categoryIndex+'.land_target'"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <b-form-input
                                                        type="number"
                                                        step="0.001"
                                                        :id="'details.'+cropTypeIndex+'.sub_type_list.'+subTypeIndex+'.crop_list.'+cropIndex+'.category_list.'+categoryIndex+'.land_target'"
                                                        v-model="category.land_target"
                                                        :placeholder="$t('cropInfo.land_target')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        @input="getProductionRatio(cropTypeIndex, subTypeIndex, cropIndex, categoryIndex)"
                                                        ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                          </b-td>
                                          <b-td>
                                            <ValidationProvider name="Production Target" :vid="'details.'+cropTypeIndex+'.sub_type_list.'+subTypeIndex+'.crop_list.'+cropIndex+'.category_list.'+categoryIndex+'.production_target'" rules="">
                                                <b-form-group
                                                        :label-for="'details.'+cropTypeIndex+'.sub_type_list.'+subTypeIndex+'.crop_list.'+cropIndex+'.category_list.'+categoryIndex+'.production_target'"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <b-form-input
                                                            type="number"
                                                            step="0.001"
                                                        :id="'details.'+cropTypeIndex+'.sub_type_list.'+subTypeIndex+'.crop_list.'+cropIndex+'.category_list.'+categoryIndex+'.production_target'"
                                                        v-model="category.production_target"
                                                        :placeholder="$t('cropInfo.production_target')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        @input="getProductionRatio(cropTypeIndex, subTypeIndex, cropIndex, categoryIndex)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                          </b-td>
                                        <b-td class="text-center">
                                          {{ category.production_ratio ? $n(category.production_ratio) : '' }}
                                        </b-td>
                                      </b-tr>
                                    </template>
                                  </template>
                                </template>
                              </template>
                            </template>
                            <template v-else>
                              <b-tr>
                                <b-td colspan="8" class="text-center">{{ $t('globalTrans.noDataFound') }}</b-td>
                              </b-tr>
                            </template>
                        </b-table-simple>
                      <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                  </b-overlay>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
  </template>
  <script>
  import { core } from '@/config/pluginInit'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
  import { cultivationCropList, cultivationStore, targetEditDataApi, cultivationUpdate } from '../../api/routes'
  import flatpickr from 'flatpickr'
  export default {
    props: ['id', 'item'],
    components: {
      ValidationObserver,
      ValidationProvider
    },
    created () {
      if (this.item) {
      this.form.fiscal_year_id = this.item.fiscal_year_id
      this.form.production_season_id = this.item.production_season_id
      this.form.damage_date = this.item.damage_date
      this.disableButton = true
      this.getEditData()
      } else {
        this.form = Object.assign({}, this.form, {
          fiscal_year_id: this.$store.state.SeedsFertilizer.currentFiscalYearId
        })
      }
    },
    mounted () {
      core.index()
      flatpickr('#achievement_date', {})
    },
    data () {
      return {
        disableButton: false,
        saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        form: {
          id: this.id ? this.id : 0,
          fiscal_year_id: 0,
          production_season_id: 0,
          details: []
        },
        cropList: []
      }
    },
    computed: {
      productionSeasonList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.productionSeasonList.filter(item => item.status === 1)
      },
      fiscalList: function () {
        return this.$store.state.commonObj.fiscalYearList
      },
      loading () {
        return this.$store.state.commonObj.loading
      },
      CropTypeList () {
        return this.$store.state.SeedsFertilizer.commonObj.CropTypeList
      },
      seedSubTypeList () {
        return this.$store.state.SeedsFertilizer.commonObj.seedSubTypeList
      },
      seedCategoryList () {
        return this.$store.state.SeedsFertilizer.commonObj.seedCategoryList
      },
      currentLocale () {
        return this.$i18n.locale
      }
    },
    watch: {
        'form.production_season_id': function (newVal, oldVal) {
          if (newVal) {
            if (!this.item) {
              this.getCropList(newVal)
            }
          } else {
            this.form.details = []
          }
        }
    },
    methods: {
        cropRowspan (checkData) {
        let total = 0
        checkData.forEach((element, key) => {
          element.crop_list.forEach((element2, key2) => {
            element2.category_list.forEach((element3, key2) => {
              total++
            })
          })
        })
        return total
      },
        categoryRowspan (checkData) {
            let total = 0
            checkData.forEach((element, key) => {
                element.category_list.forEach((element2, key2) => {
                    total++
                })
            })
            return total
        },
        async createData () {
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadinState = { loading: false, listReload: true }
        if (this.id) {
          result = await RestApi.putData(seedFertilizerServiceBaseUrl, cultivationUpdate, this.form)
        } else {
          result = await RestApi.postData(seedFertilizerServiceBaseUrl, cultivationStore, this.form)
        }
        this.$store.dispatch('mutateCommonProperties', loadinState)
        if (result.success) {
          this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
          this.$toast.success({
              title: this.$t('globalTrans.success'),
              message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
              color: '#D6E09B'
          })
          this.$bvModal.hide('modal-4')
        } else {
          this.$refs.form.setErrors(result.errors)
        }
        this.$store.dispatch('mutateCommonProperties', { loading: true })
      },
      async getCropList (seasonId) {
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        const result = await RestApi.getData(seedFertilizerServiceBaseUrl, cultivationCropList, { production_season_id: seasonId })
        if (result.success) {
          this.form.details = result.data.map(cropType => {
            cropType.sub_type_list = cropType.sub_type_list.map(subType => {
              subType.crop_list.map(crop => {
                crop.category_list = crop.category_list.map(category => {
                  const categoryObj = this.seedCategoryList.find(obj => obj.value === parseInt(category.category_id))
                  const categoryCustomItem = {
                    category_name_en: categoryObj?.text_en,
                    category_name_bn: categoryObj?.text_bn
                  }
                  return Object.assign({}, category, categoryCustomItem)
                })
              })
              const subTypeObj = this.seedSubTypeList.find(obj => obj.value === parseInt(subType.sub_type_id))
              const subTypeCustomItem = {
                sub_type_name_en: subTypeObj?.text_en,
                sub_type_name_bn: subTypeObj?.text_bn
              }
              return Object.assign({}, subType, subTypeCustomItem)
            })
            const cropTypeObj = this.CropTypeList.find(obj => obj.value === parseInt(cropType.crop_type_id))
            const cropTypeCustomItem = {
              crop_type_name_en: cropTypeObj?.text_en,
              crop_type_name_bn: cropTypeObj?.text_bn
            }
            return Object.assign({}, cropType, cropTypeCustomItem)
          })
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false })
      },
      getProductionRatio (cropTypeIndex, subTypeIndex, cropIndex, categoryIndex) {
        const category = this.form.details[cropTypeIndex].sub_type_list[subTypeIndex].crop_list[cropIndex].category_list[categoryIndex]
        if (category.land_target && category.production_target) {
          category.production_ratio = parseFloat(category.production_target) / parseFloat(category.land_target)
        }
      },
      getEditData () {
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        const params = Object.assign({}, { id: this.item.id })
        RestApi.getData(seedFertilizerServiceBaseUrl, targetEditDataApi, params).then(response => {
              if (response.success) {
                this.form.details = response.data.map(cropType => {
                  cropType.sub_type_list = cropType.sub_type_list.map(subType => {
                    subType.crop_list.map(crop => {
                      crop.category_list = crop.category_list.map(category => {
                        const categoryObj = this.seedCategoryList.find(obj => obj.value === parseInt(category.category_id))
                        const categoryCustomItem = {
                          category_name_en: categoryObj?.text_en,
                          category_name_bn: categoryObj?.text_bn
                        }
                        return Object.assign({}, category, categoryCustomItem)
                      })
                    })
                    const subTypeObj = this.seedSubTypeList.find(obj => obj.value === parseInt(subType.sub_type_id))
                    const subTypeCustomItem = {
                      sub_type_name_en: subTypeObj?.text_en,
                      sub_type_name_bn: subTypeObj?.text_bn
                    }
                    return Object.assign({}, subType, subTypeCustomItem)
                  })
                  const cropTypeObj = this.CropTypeList.find(obj => obj.value === parseInt(cropType.crop_type_id))
                  const cropTypeCustomItem = {
                    crop_type_name_en: cropTypeObj?.text_en,
                    crop_type_name_bn: cropTypeObj?.text_bn
                  }
                  return Object.assign({}, cropType, cropTypeCustomItem)
              })
              }
              this.$store.dispatch('mutateCommonProperties', { loading: false })
        })
      }
    }
  }
  </script>
