// product stock report api
export const reportHeadingList = '/seeds/config/report-heading/detail'

// Damage Information api
export const damageList = '/crop/crop-info/damage-information/list'
export const damageStore = '/crop/crop-info/damage-information/store'
export const getCropListData = '/crop/crop-info/damage-information/crop-list'
export const getEditDataApi = '/crop/crop-info/damage-information/edit'
export const damageUpdate = '/crop/crop-info/damage-information/update'

const cropInfoBaseUrl = '/crop/crop-info/'

// Crop Harvesting and Production Achievement Type api
const productionAchievementBaseUrl = cropInfoBaseUrl + 'harvest-pro-achievement/'
export const productionAchievementList = productionAchievementBaseUrl + 'list'
export const productionAchievementStore = productionAchievementBaseUrl + 'store'
export const productionAchievementUpdate = productionAchievementBaseUrl + 'update'
export const productionAchievementCropList = productionAchievementBaseUrl + 'crop-list'
export const productionAchievementAllList = productionAchievementBaseUrl + 'all-list'
// cultivation routes
const cultivationBaseUrl = cropInfoBaseUrl + 'cultivation-production-target/'
export const cultivationList = cultivationBaseUrl + 'list'
export const cultivationStore = cultivationBaseUrl + 'store'
export const cultivationCropList = cultivationBaseUrl + 'crop-list'
export const targetEditDataApi = cultivationBaseUrl + 'edit'
export const cultivationUpdate = cultivationBaseUrl + 'update'

// cultivation acheivevment
const cultivationAcheivementBaseUrl = cropInfoBaseUrl + 'cultivation-achievement/'
export const aceivementList = cultivationAcheivementBaseUrl + 'list'
export const aceivementStore = cultivationAcheivementBaseUrl + 'store'
export const cultivationAceivementList = cultivationAcheivementBaseUrl + 'target-list'
export const cultivationAceivementAllList = cultivationAcheivementBaseUrl + 'all-list'
export const cultivationAchievementUpdate = cultivationAcheivementBaseUrl + 'update'

// harvest api
export const harvestProAchievementList = '/crop/crop-info/harvest-pro-achievement/list'
export const harvestProStore = '/crop/crop-info/harvest-pro-achievement/store'
export const getHarvestCropListData = '/crop/crop-info/harvest-pro-achievement/crop-list'
